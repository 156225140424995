<template>

  <PDialogBody>

    <PDialogContent>

      <PLoader v-if="isLoading" />

      <div class="flex flex-col space-y-4" v-else>

        <div v-for="(t, tindex) in order.transactions" :key="tindex">

          <div class="flex flex-col p-4 bg-white space-y-4">
            <div class="flex items-center justify-between space-x-4">
              <div class="flex items-center">
                <div class="flex items-center justify-center w-12 h-12">
                  <div class="w-12 h-12 bg-center bg-no-repeat bg-contain" :style="`background-image:url('${assetsBaseUrl}/media.ashx?${t.productImage}&template=product-image-xsmall')`"></div>
                </div>
                <!-- <img :src="`${assetsBaseUrl}/media.ashx?${t.productImage}&template=product-image-xsmall`" /> -->
                <div class="ml-4">
                  <div class="font-semibold">{{ t.productName }}</div>
                  <div class="text-sm">{{ t.qualityName }}, {{ t.treatmentName }}</div>
                </div>
              </div>
              <div class="pr-2 font-semibold">
                {{ t.quantity }}
              </div>
            </div>
            <div class="flex flex-col pl-16 space-y-1" v-if="t.sorts && t.sorts.length > 0">

              <div
                class="text-sm font-semibold"
                v-html="$tk('EditDialogSorts.SortedTo')"
              >:</div>

              <div v-for="(s, sindex) in t.sorts" :key="sindex">
                <div class="flex items-center justify-between px-2 bg-gray-100 ">
                  <div class="flex items-center">
                    <div class="flex items-center justify-center h-9 w-9">
                    <div class="w-8 h-8 bg-center bg-no-repeat bg-contain" :style="`background-image:url('${assetsBaseUrl}/media.ashx?${s.productImage}&template=product-image-xsmall')`"></div>
                    </div>
                    <div class="ml-2 text-sm">{{ s.qualityName }}, {{ s.treatmentName }}</div>
                  </div>
                  <div class="text-sm">
                    {{ s.quantity }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </PDialogContent>

    <PDialogActions>

      <PButton
        @click="$emit('close')"
        color="secondary"
        class="ml-2"
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>

    </PDialogActions>

  </PDialogBody>
</template>

<script>

import http from "@/utilities/http"

export default {

  props: ["orderId"],

  data () {
    return {
      order: null,
      isLoading: false
    }
  },

  computed: {
    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    }
  },

  methods: {
    async getSorts () {

      this.isLoading = true

      try {

        this.order = await http.get("Order", {
          params: {
            id: this.orderId,
            withSort: true,
            withTempSortLines: false,
            withTempSortLinesPivoted: false,
            withQualityChanges: true,
            withEmployees: false
          }
        })

      } catch (error) {

        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })

      }

      this.isLoading = false
    }
  },

  async created () {
    await this.getSorts()
  }

}
</script>

<style scoped>

td {
  padding: theme("spacing.4")
}

</style>