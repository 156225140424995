<template>
  <PDialogBody>
    <div
      v-if="isLoading"
      class="p-4"
    >
      <PLoader />
    </div>

    <template v-else>
      <div class="flex flex-col flex-1 overflow-y-hidden bg-gray-100 shadow-inner">
        <POrderModified
          v-if="order"
          :orderId="order.id"
          :orderModified="order.modified"
          :orderModifiedBy="order.modifiedBy"
          :disabled="isUpdating"
          @modified="onModified"
          @dismiss="init"
        />

        <div
          v-if="order"
          class="flex-1 p-4 overflow-y-auto"
        >
          <div class="p-4 bg-green-100 rounded-lg">
            <ToFields
              :order="order"
              v-if="isTO"
            />
            <HeFields
              :order="order"
              v-if="isHE"
            />
            <GlFields
              v-model="glFieldValues"
              :view="view"
              v-if="isGL || isOO"
            />
          </div>

          <div
            class="p-4 mt-4 bg-orange-200 rounded-lg"
            v-if="order.interCompanyIdTo && order.customsStatus !== 'done'"
          >
            <PToggle
              :disabled="order.sendKgh"
              :label="`${$tk(
                'EditDialogDetails.CustomsClearanceShouldBeHandeledByKgh'
              )} (${interCompanyIdToCountry}).`"
              :value="order.sendKgh"
              @input="onSendKghToggle"
            />
            <PConfirm
              :title="$tk('Common.Order.Customs')"
              type="Warning"
              @accept="onKghConfirm"
              @reject="onKghClose"
              v-if="confirmKghVisible"
            >
              <span v-html="$tk(
                'EditDialogDetails.AreYouSureThatCustomsClearanceShouldBeHandelerByKGH'
              )
                "></span>
              ({{ interCompanyIdToCountry }})
              <div
                class="mt-4 font-semibold text-red-600"
                v-html="$tk('EditDialogDetails.ThisActionIsPermanent')"
              ></div>
            </PConfirm>
            <PConfirm
              :title="$tk('Common.Order.Customs')"
              type="Warning"
              :rejectButtonText="$tk('EditDialogDetails.NoGoToRegistration')"
              :acceptButtonText="$tk('EditDialogDetails.YesProceed')"
              @accept="onConfirmCustoms"
              @reject="confirmCustomsVisible = false"
              v-if="confirmCustomsVisible"
            >
              {{ $tk('EditDialogDetails.ConfirmCustomsRegistered') }}
            </PConfirm>
          </div>

          <div
            v-if="isExpeditionView && !isConfirmed"
            class="flex flex-wrap items-center gap-4 p-4 mt-4 bg-white"
          >
            <PInput
              class="w-full"
              :required="true"
              :label="this.$t('Common.Order.MyReference')"
              v-if="config?.theme === 'swa' && isTO"
              v-model="order.receiversRef"
            />

            <PInput
              class="w-full"
              :required="true"
              :label="this.$t('Common.Order.MyReference')"
              v-if="config?.theme === 'swa' && isHE"
              v-model="order.sendersRef"
            />

            <PInput
              class="flex-1"
              :label="this.$t('Common.Order.CarNo')"
              v-model="order.shipmentCarNumber"
            />
            <PInput
              class="w-1/2"
              :required="true"
              :label="this.$t('Common.Order.CarNo2')"
              v-if="config?.theme === 'swa'"
              v-model="order.shipmentCarNumber2"
            />
            <div
              class="flex w-full gap-4"
              v-if="config?.theme === 'swa'"
            >
              <PInput
                class="w-1/2"
                :label="$t('Common.Order.Sealing')"
                v-model="order.sealingId"
              />
              <div class="w-1/2">
                <div class="flex justify-between">
                  <p-label :label="$t('Common.Order.Cartman')" />
                  <button
                    @click.prevent="onNewCartman()"
                    class="mb-1 text-sm text-green-500"
                    href
                  >
                    {{ $t("EditDialogDetails.NewCartman") }}
                  </button>
                </div>
                <p-select
                  v-model="order.cartmanId"
                  :items="cartmen"
                  itemText="name"
                  itemValue="id"
                  searchable
                />
              </div>
            </div>
            <div
              class="w-full pt-2 mt-2 border-t"
              v-if="location.signatureMandatory"
            >
              <div class="flex items-center justify-between mb-1">
                <label class="block text-sm">{{
                  $t("Common.General.Signature")
                }}</label>
                <a
                  class="text-sm underline hover:text-green-500"
                  href
                  @click.prevent="onClearSignature"
                >{{ $t("EditDialogDetails.ResetSignature") }}</a>
              </div>
              <div class="flex justify-center bg-gray-400 border border-gray-400 rounded-md">
                <VueSignaturePad
                  width="500px"
                  height="85px"
                  ref="signaturePad"
                  :options="{
                    backgroundColor: 'rgba(255,255,255,1)',
                    onEnd: onDrawEnd,
                  }"
                />
              </div>
            </div>
          </div>

          <div
            class="p-4 mt-4 bg-orange-200 rounded-lg"
            v-if="order.interCompanyIdTo && order.customsDone"
          >
            <PTranslation k="EditDialogDetails.CustomsDeclaredBySmartRetur" />.
          </div>

          <template v-if="order.transactions.length > 0">
            <table class="w-full mt-8">
              <thead>
                <tr>
                  <th
                    class="pb-1 text-sm font-normal text-left"
                    colspan="2"
                  ></th>
                  <th
                    class="w-24 pb-1 text-xs text-gray-600 text-medium"
                    v-html="orderedLabel"
                  ></th>
                  <th
                    class="w-24 pb-1 text-xs text-gray-600 text-medium"
                    v-html="pickedLabel"
                    v-if="showPicked"
                  ></th>
                  <th
                    class="w-24 pb-1 text-xs text-gray-600 text-medium"
                    v-html="confirmedLabel"
                  ></th>
                  <th>{{ '' }}</th>
                </tr>
              </thead>
              <tbody
                v-for="(transaction, index) in order.transactions"
                :key="index"
              >
                <tr>
                  <td class="w-16 py-2 align-top">
                    <img
                    alt=""
                      :src="`${assetsBaseUrl}/media.ashx?${transaction.productImage}&template=product-image-xsmall`" />
                  </td>
                  <td class="py-2">
                    <div class="font-semibold">
                      {{ transaction.productName }}
                    </div>
                    <template v-if="
                      !isGL &&
                      (isDeliveryView && transaction.isPooling) === false
                    ">
                      <div class="text-sm">
                        {{ transaction.qualityName }},
                        {{ transaction.treatmentName }}
                      </div>
                    </template>
                  </td>
                  <td class="p-2 text-sm text-center">
                    <div class="relative">
                      <div
                        v-if="transaction.quantityExtra > 0"
                        class="absolute z-10 mt-1 text-xs text-red-500 top-2 left-2"
                        v-tooltip="{
                          content: $t(
                            'Common.General.OfWhichUnderOtherProducts',
                            {
                              count: transaction.quantityExtra,
                              name: transaction.productName.toLowerCase(),
                            }
                          ),
                          delay: {
                            show: 100,
                            hide: 100,
                          },
                        }"
                      >
                        <FontAwesomeIcon :icon="['fad', 'pallet-alt']" />
                        {{ transaction.quantityExtra }}
                      </div>
                      <PNumber
                        class="w-24"
                        v-model="transaction.quantityOrdered"
                        :extra="transaction.quantityExtra"
                        :readonly="!canEditOrdered"
                        :disabled="isModified || isUpdating"
                      />
                    </div>
                  </td>
                  <td
                    v-if="showPicked"
                    class="p-2 text-sm text-center"
                  >
                    <PNumber
                      class="w-24"
                      v-model="transaction.quantityPicked"
                      :readonly="!canEditPicked"
                      :warn="quantityPicked20PercentAbove(transaction)"
                      :warn-below="quantityPicked20PercentBelow(transaction)"
                      :warn-message="$t(
                        'EditDialogDetails.QuantityPickedOutside20PercentRange'
                      )
                        "
                      :focus="focusPicked(index)"
                      :disabled="isModified || isUpdating"
                    />
                  </td>
                  <td class="p-2 text-sm text-center">
                    <PNumber
                      class="w-24"
                      v-model="transaction.quantityConfirmed"
                      :readonly="!canEditConfirmed"
                      :disabled="isModified || isUpdating"
                    />
                  </td>
                  <td></td>
                </tr>

                <PRealQuality
                  v-if="canEditReal"
                  :readonly="canChangeRealQuality === false"
                  :transaction="transaction"
                  @change="onRealQualityChange"
                />

                <PRealQuality
                  v-if="canEditReal"
                  :readonly="canChangeRealQuality === false"
                  :transaction="transaction"
                  @change="onRealQualityChange"
                  qualityIdPropertyName="qualityIdReal2"
                  namePropertyName="qualityNameReal2"
                  quantityPropertyName="quantityReal2"
                />
                <tr>
                  <td></td>
                  <td colspan="3">
                    <TransactionCustomsAccordion
                      v-if="showCustoms"
                      class="mt-2"
                      :transaction="transaction"
                    >
                      <span
                        :class="['inline-flex flex-row gap-1', transaction.quantityPicked > 0 ? 'text-green-500' : 'text-gray-500']"
                      >
                        <FontAwesomeIcon
                          :icon="['fas', 'person-military-pointing']"
                          class="my-auto"
                        />
                        <span class="my-auto">{{ $tk('Common.General.Customs') }}</span>
                      </span>
                    </TransactionCustomsAccordion>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>

          <PAddProduct
            v-if="canAddProduct && !isUpdating"
            :order="order"
            @add="onAddProduct"
          />
        </div>
      </div>

      <PDialogActions
        v-if="order"
        :split="canDelete"
      >
        <div>
          <PButton
            icon="trash-alt"
            color="secondary"
            @click="onDelete"
            v-if="canDelete"
            :disabled="isModified"
          >
            <span v-html="$tk('Common.Actions.Delete')"></span>
          </PButton>
        </div>

        <div class="flex items-center self-end space-x-2">
          <PButton
            @click="onClose"
            color="secondary"
          >
            <span v-html="$tk('Common.Actions.Close')"></span>
          </PButton>

          <PButton
            @click="onSend"
            v-if="sendVisible"
            :disabled="!canSend || isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.SendAndClose')"></span>
          </PButton>

          <PButton
            @click="onSendAndShowWaybill"
            v-if="sendVisible"
            :disabled="!canSend || isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.SendAndShowWaybill')"></span>
          </PButton>

          <PButton
            @click="onSaveSent"
            v-if="canSaveSent"
            :disabled="isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.Save')"></span>
          </PButton>

          <PButton
            @click="onDisagree"
            v-if="canDisagree"
            color="danger"
            :disabled="isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.Disagree')"></span>
          </PButton>

          <PButton
            @click="onSaveDisagreed"
            v-if="canSaveDisagreed"
            color="danger"
            :disabled="isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.Save')"></span>
          </PButton>

          <PButton
            @click="onConfirm"
            v-if="canConfirm"
            :disabled="isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.Confirm')"></span>
          </PButton>

          <PButton
            @click="onReturn"
            v-if="canReturn"
            :disabled="isModified"
            :loading="isUpdating"
          >
            <span v-html="$tk('Common.Actions.Return')"></span>
          </PButton>
        </div>

        <PConfirm
          v-if="confirmDeletionsVisible"
          :loading="isUpdating"
          :title="$tk('EditDialogDetails.DeleteLinesWith0Picked')"
          @accept="onConfirmDeletions"
          @reject="confirmDeletionsVisible = false"
        >
          <PTranslation k="EditDialogDetails.DeleteLinesWith0PickedText" />
        </PConfirm>
      </PDialogActions>
    </template>
  </PDialogBody>
</template>

<script>
import axios from "axios";
import http from "@/utilities/http";
import { mapGetters } from "vuex";
import { getStatusText } from "@/utilities/helpers";
import { every, find, filter, forEach, get, some, pick } from "lodash";
import GlFields from "./partials/GlFields";
import HeFields from "./partials/HeFields";
import PAddProduct from "../components/PAddProduct";
import PLabel from "@/components/forms/partials/PLabel";
import POrderModified from "@/components/POrderModified";
import PRealQuality from "../components/PRealQuality";
import ToFields from "./partials/ToFields";
import TransactionCustomsAccordion from "./partials/TransactionCustomsAccordion";

import { getConfig } from "@/config";

const config = getConfig();

export default {
  components: {
    GlFields,
    HeFields,
    PAddProduct,
    PLabel,
    POrderModified,
    PRealQuality,
    ToFields,
    TransactionCustomsAccordion,
  },
  props: {
    orderId: { type: String, required: true, },
    showReal: { type: Boolean, default: false, },
    view: { type: String, default: "", },
  },
  data() {
    return {
      order: null,
      isLoading: false,
      isUpdating: false,
      isModified: false,
      sendKgh: false,
      confirmKghVisible: false,
      confirmDeletionsVisible: false,
      confirmDeletionsCallback: null,
      hasDrawnSignature: false,
      config,
      cartmen: [],
      customsConfimed: false,
      confirmCustomsVisible: false

    };
  },

  computed: {
    ...mapGetters(["location", "customer"]),

    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl;
    },

    orderedEqualsConfirmed() {
      return every(
        get(this.order, "transactions", []),
        (t) => t.quantityOrdered === t.quantityConfirmed
      );
    },

    pickedEqualsConfirmed() {
      return every(
        get(this.order, "transactions", []),
        (t) => t.quantityPicked === t.quantityConfirmed
      );
    },

    someQuantitiesPicked() {
      return some(
        get(this.order, "transactions", []),
        (t) => t.quantityPicked > 0
      );
    },

    allQuantitiesPicked() {
      return every(
        get(this.order, "transactions", []),
        (t) => t.quantityPicked > 0
      );
    },

    isSender() {
      return get(this.order, "locationIdFrom", "") === this.location.id;
    },

    isOrdersView() {
      return this.view === "orders";
    },

    isReceptionView() {
      return this.view === "reception";
    },

    isExpeditionView() {
      return this.view === "expedition";
    },

    isDeliveryView() {
      return this.view === "delivery";
    },

    isReceiver() {
      return this.locationIdTo === this.location.id;
    },

    isSR() {
      return this.location.isSmartRetur;
    },

    status() {
      return get(this.order, "transactionStatusId", -1);
    },

    isConfirmedSR() {
      return this.status === 2;
    },

    isSent() {
      return this.status === 4;
    },

    isDisagreement() {
      return this.status === 6;
    },

    isConfirmed() {
      return this.status === 5;
    },

    locationIdTo() {
      return get(this.order, "locationIdTo", "");
    },

    locationIdFrom() {
      return get(this.order, "locationIdFrom", "");
    },

    transactionTypeId() {
      return get(this.order, "transactionTypeId", "");
    },

    isTO() {
      return this.transactionTypeId === "TO";
    },

    isHE() {
      return this.transactionTypeId === "HE";
    },

    isGL() {
      return this.transactionTypeId === "GL";
    },

    isOO() {
      return this.transactionTypeId === "OO";
    },

    isRO() {
      return this.transactionTypeId === "RO";
    },

    picked() {
      return get(this.order, "quantityPicked", 0);
    },

    confirmed() {
      return get(this.order, "quantityConfirmed", 0);
    },

    canEditOrdered() {
      return (
        this.isSender &&
        (this.isGL || this.isOO) &&
        some([4, 6], (id) => id === this.status)
      );
    },

    canEditPicked() {
      // const okTO = this.isTO && this.isExpeditionView && this.isSR && some([2, 4, 6], id => id === this.status)
      // const okHE = this.isHE && this.isExpeditionView && this.isSender && some([2, 4, 6], id => id === this.status)

      const okTO =
        this.isTO && this.isSR && some([2, 4, 6], (id) => id === this.status);
      const okHE =
        this.isHE &&
        this.isSender &&
        some([2, 4], (id) => id === this.status) &&
        this.isExpeditionView;
      const okRO =
        this.isRO && this.isSR && some([2], (id) => id === this.status);
      return okTO || okHE || okRO;
    },

    showCustoms() {
      return this.order.interCompanyIdTo !== '' && this.order.interCompanyIdTo !== null && this.order.interCompanyIdTo !== undefined
    },

    canEditConfirmed() {
      // const okTO = this.isTO && this.isReceptionView && this.isReceiver && some([4, 6], id => id === this.status)
      // const okHE = this.isHE && this.isReceptionView && this.isReceiver && some([4, 6], id => id === this.status)

      const okTO =
        this.isReceiver &&
        this.isTO &&
        some([4, 6], (id) => id === this.status) &&
        this.isReceptionView;
      const okHE =
        this.isReceiver &&
        this.isHE &&
        some([4, 6], (id) => id === this.status) &&
        this.isReceptionView;
      const okGL =
        this.isReceiver &&
        (this.isGL || this.isOO) &&
        some([4, 6], (id) => id === this.status);
      return okTO || okHE || okGL;
    },

    canDelete() {
      const okGL =
        this.isGL && this.isSent && this.locationIdFrom === this.location.id;
      const okOO =
        this.isOO && this.isSent && this.locationIdFrom === this.location.id;
      const okGL_ReceiverRegistered =
        get(this.order, "isReceiverRegistered", false) &&
        this.isGL &&
        this.locationIdTo === this.location.id &&
        this.isReceptionView;
      const okOO_ReceiverRegistered =
        get(this.order, "isReceiverRegistered", false) &&
        this.isOO &&
        this.locationIdTo === this.location.id &&
        this.isReceptionView;
      return okGL || okGL_ReceiverRegistered || okOO || okOO_ReceiverRegistered;
    },

    sendVisible() {
      const okTO =
        this.isExpeditionView &&
        this.isTO &&
        this.status === 2 &&
        this.isSender &&
        this.isSR;
      const okHE =
        this.isExpeditionView &&
        this.isHE &&
        this.status === 2 &&
        this.isSender;
      return okTO || okHE;
    },

    canSend() {
      const signatureOk =
        !this.location.signatureMandatory ||
        (this.location.signatureMandatory && this.hasDrawnSignature);
      const shipmentCarNumber2Ok =
        config?.theme !== "swa" ||
        (config?.theme === "swa" &&
          (this.order?.shipmentCarNumber2 ?? "") !== "");
      return (
        signatureOk &&
        shipmentCarNumber2Ok &&
        this.sendVisible &&
        this.someQuantitiesPicked
      );
    },

    canSaveSent() {
      const okTO =
        this.isTO &&
        this.status === 4 &&
        this.isSender &&
        this.isSR &&
        this.someQuantitiesPicked;
      const okHE_2 =
        this.isHE &&
        this.status === 2 &&
        this.isSender &&
        this.isExpeditionView &&
        this.isSR;
      const okHE_4 =
        this.isHE &&
        this.status === 4 &&
        this.isSender &&
        this.isExpeditionView;
      const okGL =
        (this.isGL || this.isOO) && this.status === 4 && this.isSender;
      return okTO || okHE_2 || okHE_4 || okGL;
    },

    canDisagree() {
      const okTO =
        this.isTO &&
        this.isReceiver &&
        this.status === 4 &&
        !this.pickedEqualsConfirmed &&
        this.isReceptionView;
      // Disabled after Toms request: 14.12.2021
      // const okHE = this.isHE && this.isReceiver && this.status === 4 && !this.pickedEqualsConfirmed && this.isReceptionView
      const okGL =
        (this.isGL || this.isOO) &&
        this.isReceiver &&
        this.status === 4 &&
        !this.orderedEqualsConfirmed;
      // return okTO || okHE || okGL
      return okTO || okGL;
    },

    canSaveDisagreed() {
      const okTO =
        this.isTO &&
        this.status === 6 &&
        !this.pickedEqualsConfirmed &&
        (this.isReceptionView || this.isExpeditionView);
      // Disabled after Toms request: 14.12.2021
      // const okHE = this.isHE && this.status === 6 && !this.pickedEqualsConfirmed && this.isReceptionView
      const okGL =
        (this.isGL || this.isOO) &&
        this.status === 6 &&
        !this.orderedEqualsConfirmed;
      // return okTO || okHE || okGL
      return okTO || okGL;
    },

    canConfirm() {
      const okTO_4 =
        this.isTO &&
        this.isReceiver &&
        this.status === 4 &&
        this.pickedEqualsConfirmed &&
        this.isReceptionView;
      const okTO_6 =
        this.isTO &&
        this.status === 6 &&
        this.pickedEqualsConfirmed &&
        (this.isReceptionView || this.isExpeditionView);
      // const okHE = this.isHE && this.isReceiver && (this.status === 4 || this.status === 6) && this.pickedEqualsConfirmed && this.isReceptionView
      const okHE =
        this.isHE &&
        this.isReceiver &&
        (this.status === 4 || this.status === 6) &&
        this.isReceptionView;
      const okGL_4 =
        (this.isGL || this.isOO) &&
        this.isReceiver &&
        this.status === 4 &&
        this.orderedEqualsConfirmed;
      const okGL_6 =
        (this.isGL || this.isOO) &&
        this.status === 6 &&
        this.orderedEqualsConfirmed;
      return okTO_4 || okTO_6 || okHE || okGL_4 || okGL_6;
    },

    canReturn() {
      // status returned = 17
      const okRO = this.isRO && this.isSR && this.status === 2;
      return okRO;
    },

    canAddProduct() {
      // const isPooling = this.location.poolingGroupId

      // let isValid = some([4, 6], id => id === this.status) && !this.isOrdersView

      // let isValidSR = false
      // if(this.location.isSmartRetur) {
      //   const someProductsArePooling = every(this.order.transactions,
      //     transaction => transaction.isPooling)
      //   isValidSR = this.status === 2 && !someProductsArePooling
      // }

      // return isPooling && (isValid || isValidSR) && this.isGL && this.order

      return this.isGL && this.isSender && this.status !== 5;
    },

    // canEditReceiversRef () {
    //   const okTO = this.isTO && (this.isSent || this.isDisagreement) && this.isSender
    //   const okHE = this.isHE && this.isSent && this.isReceiver
    //   const okGL = (this.isGL || this.isOO) && this.isSender && (this.isSent || this.isDisagreement)
    //   return (okTO || okHE || okGL) && !this.isExpeditionView
    // },

    canEditReal() {
      return (
        this.isExpeditionView &&
        this.isTO &&
        this.isSR &&
        (this.isConfirmedSR || this.isSent)
      );
    },

    canChangeRealQuality() {
      return some([2, 4, 6], (id) => id === this.status);
    },

    transactionIsValid() {
      return this.product !== null && this.quantity > 0;
    },

    statusText() {
      return getStatusText(get(this.order, "transactionStatus", ""), this);
    },

    interCompanyIdToCountry() {
      const id = get(this.order, "interCompanyIdTo", "");
      return id === "NOR"
        ? this.$tk("Common.Countries.NOR", true)
        : id === "SVE"
          ? this.$tk("Common.Countries.SVE", true)
          : id === "DAN"
            ? this.$tk("Common.Countries.DAN", true)
            : id === "FIN"
              ? this.$tk("Common.Countries.FIN", true)
              : "?";
    },

    dateSendLabel() {
      return this.isTO
        ? this.$tk("Common.Order.SendDate")
        : this.isHE
          ? this.$tk("Common.Order.PickupDate")
          : this.isGL
            ? this.$tk("Common.Order.SendDate")
            : this.$tk("Common.General.Date");
    },

    orderedLabel() {
      return this.isGL
        ? this.$tk("Common.OrderStatus.Sent")
        : this.$tk("Common.OrderStatus.Ordered");
    },

    pickedLabel() {
      return this.$tk("Common.OrderStatus.PickedShort");
    },

    confirmedLabel() {
      return this.isGL
        ? this.$tk("Common.OrderStatus.Received")
        : this.$tk("Common.OrderStatus.Confirmed");
    },

    showPicked() {
      return !this.isGL;
    },

    glFieldValues: {
      get() {
        return pick(this.order, [
          "dateSend",
          "sendersRef",
          "receiversRef",
          "locationIdFrom",
          "locationNameFrom",
          "locationIdTo",
          "locationNameTo",
          "transactions", // needed to validate valid to locations
          "transactionStatusId", // needed to determine if fields are editable
        ]);
      },
      set(value) {
        this.order.dateSend = value.dateSend;
        this.order.sendersRef = value.sendersRef;
        this.order.receiversRef = value.receiversRef;
        this.order.locationIdTo = value.locationIdTo;
      },
    },
  },
  methods: {
    async onSend() {
      if (this.isExpeditionView && !this.allQuantitiesPicked) {
        this.confirmDeletionsVisible = true;
        this.confirmDeletionsCallback = this.onSend;
      } else {
        await this.saveOrder(4);
        this.confirmDeletionsVisible = false;
      }
    },
    async onSendAndShowWaybill() {
      if (this.isExpeditionView && !this.allQuantitiesPicked) {
        this.confirmDeletionsVisible = true;
        this.confirmDeletionsCallback = this.onSendAndShowWaybill;
      } else {
        await this.saveOrder(4, false);
        this.$emit("showWaybill");
        this.confirmDeletionsVisible = false;
      }
    },
    async onSaveSent() {
      if (this.isExpeditionView && !this.allQuantitiesPicked) {
        this.confirmDeletionsVisible = true;
        this.confirmDeletionsCallback = this.onSaveSent;
      } else {
        await this.saveOrder(4);
        this.confirmDeletionsVisible = false;
      }
    },
    async onConfirmDeletions() {
      this.order.transactions = filter(
        this.order.transactions,
        (t) => t.quantityPicked > 0
      );
      await this.confirmDeletionsCallback();
    },
    async onDisagree() {
      await this.saveOrder(6);
    },
    async onSaveDisagreed() {
      await this.saveOrder(6);
    },
    async onConfirm() {
      await this.saveOrder(5);
    },
    async onReturn() {
      await this.saveOrder(17);
    },
    onModified() {
      this.isModified = true;
    },
    onAddProduct(product) {
      this.order.transactions.push({
        ...pick(product, [
          "productId",
          "productName",
          "treatmentId",
          "treatmentName",
          "qualityId",
          "qualityName",
          "isPooling",
          "isCommission",
        ]),
        productImage: product.image,
        quantityOrdered: 0, // ((this.isGL || this.isOO) && this.isSender) ? this.quantity :
        quantityPicked: 0,
        quantityConfirmed: 0, // ((this.isGL || this.isOO) && this.isReceiver) ? this.quantity
      });
      // this.product = null,
      // this.quantity = 0
    },
    async onNewCartman() {
      const name = prompt(this.$t("EditDialogDetails.EnterNameOfNewCartman"));
      if (!`${name}`.trim()) return;
      const existing = this.cartmen.find((c) => c.name === name);
      if (existing) {
        this.order.cartmanId = existing.id;
      } else {
        try {
          const cartman = await http.post("Cartman", { name });
          this.cartmen = await http.get("CartmenList");
          this.order.cartmanId = cartman.id;
        } catch {
          // cartman ex
        }
      }
    },
    onSendKghToggle() {
      if (!this.customsConfimed) this.confirmCustomsVisible = true
      else if (this.order.sendKgh === false && this.status !== 5) {
        this.confirmKghVisible = true;
      }
    },
    onConfirmCustoms() {
      this.customsConfimed = true;
      this.confirmCustomsVisible = false
      this.confirmKghVisible = true;
    },
    onKghClose() {
      this.customsConfimed = false
      this.confirmKghVisible = false;
    },
    async onKghConfirm() {
      try {
        await http.post("OrderSendKgh", {
          orderId: this.order.id,
          sendKgh: true,
        });

        this.order.sendKgh = true;
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }

      this.confirmKghVisible = false;
    },

    onRealQualityChange(data) {
      let transaction = find(
        this.order.transactions,
        (t) => t.id === data.transactionId
      );

      if (transaction) {
        transaction[data.qualityIdPropertyName] = data.qualityIdReal;
        transaction[data.quantityPropertyName] = data.quantityReal;
      }
    },

    async onDelete() {
      try {
        await http.delete("Order", {
          params: {
            id: this.orderId,
          },
        });
        this.$emit("close");
      } catch (error) {
        this.$notifyError(error.reason);
      }
    },

    onClose() {
      this.$emit("close");
    },

    onClearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.hasDrawnSignature = false;
    },

    onDrawEnd() {
      this.hasDrawnSignature = true;
    },

    async saveOrder(transactionStatus, closeWhenSaved = true) {
      this.isUpdating = true;
      this.order.commit = true;

      try {
        if (this.isGL && !this.order.isReceiverRegistered) {
          this.order.transactions = filter(
            this.order.transactions,
            (t) => t.quantityOrdered > 0
          );
        }

        const order = await http.post("Order", this.order);

        if (order.transactionStatusId !== transactionStatus) {
          await http.post("OrderStatus", {
            id: order.id,
            transactionStatus,
          });
        }

        if (this.location.signatureMandatory) {
          const { isEmpty, data } =
            this.$refs.signaturePad.saveSignature("image/jpeg");
          if (!isEmpty) {
            const formData = new FormData();
            formData.append("type", "image/jpeg");
            formData.append("name", `${order.id}.jpg`);
            formData.append("data", data.split(",")[1]);
            formData.append("orderId", order.id);
            const url = `${config?.apiUrl}signature?orderId=${order.id
              }&authToken=${encodeURIComponent(localStorage.getItem("token"))}`;
            await axios.post(url, formData);
          }
        }
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }

      if (closeWhenSaved) {
        this.$emit("close");
      }

      this.isUpdating = false;
    },

    async init() {
      this.isLoading = true;
      this.isModified = false;

      try {
        if (config?.theme === "swa") {
          this.cartmen = (await http.get("CartmenList")).filter((c) => c.Valid);
        }

        this.order = await http.get("Order", {
          params: {
            id: this.orderId,
          },
        });

        // if status is sent and users can edit confirmed, default to ordered (gl/oo) or
        // picked (to) quantity so that receiver can simply click confirm and not having
        // to enter received  to be able to confirm.

        if (this.canEditConfirmed) {
          forEach(this.order.transactions, (t) => {
            if (t.quantityConfirmed === 0) {
              switch (t.transactionTypeId) {
                case "TO":
                  t.quantityConfirmed = t.quantityPicked;
                  break;
                case "GL":
                case "OO":
                  t.quantityConfirmed = t.quantityOrdered;
                  break;
              }
            }
          });
        }

        if (this.isRO && this.status === 2 && this.isSR) {
          forEach(this.order.transactions, (t) => {
            if (t.quantityPicked === 0) {
              t.quantityPicked = t.quantityOrdered;
            }
          });
        }

        if (
          this.isTO &&
          this.isExpeditionView &&
          (this.status === 4 || this.status === 6) &&
          this.isSender
        ) {
          forEach(this.order.transactions, (t) => {
            if (t.quantityPicked === 0) {
              t.quantityPicked = t.quantityOrdered;
            }
          });
        }

        // he
        if (
          this.isHE &&
          this.isReceptionView &&
          (this.status === 4 || this.status === 6) &&
          this.isReceiver
        ) {
          forEach(this.order.transactions, (t) => {
            if (t.quantityConfirmed === 0) {
              t.quantityConfirmed = t.quantityPicked || t.quantityOrdered;
            }
          });
        }

        // default to sent on gl
        if ((this.isGL || this.isOO) && this.status === 4 && this.isReceiver) {
          forEach(this.order.transactions, (t) => {
            if (t.quantityConfirmed === 0) {
              t.quantityConfirmed = t.quantityPicked || t.quantityOrdered;
            }
          });
        }
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }

      this.isLoading = false;

      // ui must render before signaturePad is accessible
      if (this.location.signatureMandatory) {
        this.$nextTick(async () => {
          try {
            var signature = await http.get("Signature", {
              params: { name: `${this.orderId}.jpg`, withData: true },
            });
            this.$refs.signaturePad.fromDataURL(
              `data:image/jpeg;base64,${signature.data}`
            ); // , { ratio: 1, width: 400, height: 200, xOffset: 100, yOffset: 50 }
          } catch {
            // no signature
          }
        });
      }
    },

    focusPicked(index) {
      return (
        index === 0 &&
        this.isExpeditionView &&
        this.canEditPicked &&
        this.order.transactions.length > 0
      );
    },

    quantityPicked20PercentBelow(transaction) {
      return transaction.quantityOrdered * 0.8;
    },

    quantityPicked20PercentAbove(transaction) {
      return transaction.quantityOrdered * 1.2;
    },
  },

  async created() {
    await this.init();
  },
};
</script>