<template>
  <div class="p-4 border-b border-gray-300" v-if="isModified">
    <PAlert type="error">
      <div class="flex items-center justify-between">

        <div>
          <span class="font-semibold">{{ modifiedBy.name }}</span> <span v-html="$tk('OrderDialog.UpdatedThisOrder')"></span> <span class="font-semibold">{{ modified | date }}</span> <span v-html="$tk('Common.General.At')"></span> <span class="font-semibold">{{ modified | time }}</span>.
        </div>

        <PButton
          color="danger"
          class="ml-4 whitespace-no-wrap"
          @click="onReloadClick"
        >
          <span v-html="$tk('OrderDialog.ReloadOrder')"></span>
        </PButton>

      </div>
    </PAlert>
  </div>
</template>

<script>

import http from "@/utilities/http"
import {
  setIntervalAsync,
  clearIntervalAsync
} from "set-interval-async/dynamic"

export default {

  props: {
    orderId: {
      type: String,
      default: ""
    },
    orderModified: {
      type: String,
      default: ""
    },
    orderModifiedBy: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      timer: null,
      modified: "",
      modifiedBy: null
    }
  },

  computed: {
    isModified () {
      return this.modified
    }
  },

  methods: {

    async checkIfModified () {
      if (this.orderId && !this.disabled && this.orderModified && this.orderModifiedBy) {

        try {
          const { modified, modifiedBy } = await http.post("OrderModified", { id: this.orderId })

          const isDifferentTime = this.orderModified !== modified

          if (isDifferentTime) {
            this.modified = modified
            this.modifiedBy = modifiedBy
            this.$emit("modified")
          }
        }
        catch (error) {
          this.$appInsights.trackException({exception: error })
        }
      }
    },

    onReloadClick () {
      this.$emit("dismiss")
    }

  },

  async beforeDestroy () {
    if (this.timer) {
      await clearIntervalAsync(this.timer)
    }
  },

  watch: {

    disabled: {
      handler: async function (val) {
        if (!val) {
          this.timer = setIntervalAsync(async () => {
            await this.checkIfModified()
          }, 15000)
        }
        else if (this.timer) {
          await clearIntervalAsync(this.timer)
        }
      },
      immediate: true
    },

    // an EntityModified error can occur outside when
    // saving, if this occurs trigger will turn true
    trigger: async function (val) {

      if (val) {
        const { modified, modifiedBy } = await http.get("Order", { params: { id: this.orderId }})

        this.modified = modified
        this.modifiedBy = modifiedBy
      }
    }

  }

}
</script>