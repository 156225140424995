<template>

  <PDialogBody>

    <PDialogContent :loading="isLoading">

      <div class="flex flex-col space-y-6">
        <div class="flex items-center" v-for="(entry, index) in entries" :key="index">
          <div class="flex items-center justify-center w-8 h-8 bg-white border-2 border-green-500 rounded-full">
            <font-awesome-icon class="text-green-500" :icon="['far', 'check']" />
          </div>
          <div class="ml-4">

            <div
              class="font-medium"
              v-html="statusText(entry.newStatus)"
            ></div>

            <div class="text-sm text-gray-700">
              {{ entry.createdLocal | dateAndTime }}<br/>
              {{ entry.createdBy.name }}
            </div>

          </div>
        </div>
      </div>

    </PDialogContent>

    <PDialogActions>

      <PButton
        class="ml-2"
        color="secondary"
        @click="$emit('close')"
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>

    </PDialogActions>

  </PDialogBody>

</template>

<script>

import http from "@/utilities/http"
import { getStatusText } from "@/utilities/helpers"

export default {

  props: ["orderId"],

  data () {
    return {
      entries: [],
      isLoading: false
    }
  },

  methods: {

    async getStatusLog () {
      this.isLoading = true
      try {
        this.entries = await http.get("orderstatuslog", { params: { orderId: this.orderId }})
      } catch (error) {
        this.$store.dispatch("notify", { type: "negative", text: error.reason })
      }
      this.isLoading = false
    },

    statusText (status) {
      return getStatusText(status, this)
    }

  },

  async created () {
    await this.getStatusLog()
  }

}
</script>